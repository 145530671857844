import React from "react"
import HeroImage from "./components/HeroImage"

export default function Quote() {
  return (
    <section className="section-quote">
      <div className="container">
        <div className="columns is-centered is-mobile">
          <div className="column">
            <h1>&ldquo;</h1>
            <div className="columns is-centered">
              <div className="column is-three-fifths">
                <h3>
                  Muoversi sui territori della memoria dovrebbe essere sempre un
                  percorso culturale e di arricchimento, per conoscere il
                  territorio in cui ci si addentra e per evitare gli errori già
                  commessi nel tempo: senza un rigoroso pensiero critico sul
                  proprio passato, infatti, guardare indietro non serve. Anzi,
                  rischia di diventare un esercizio puramente nostalgico.{" "}
                </h3>
                <p className="mt-4">
                  <b>Michele Lupi</b>
                </p>
                <p>Men's Collections Visionary del gruppo TOD'S</p>
                <p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
